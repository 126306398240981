// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-dmdm-hydantoin-mdx": () => import("./../../../src/pages/blog/dmdm-hydantoin.mdx" /* webpackChunkName: "component---src-pages-blog-dmdm-hydantoin-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-minoxidil-mdx": () => import("./../../../src/pages/products/minoxidil.mdx" /* webpackChunkName: "component---src-pages-products-minoxidil-mdx" */),
  "component---src-pages-progress-photos-tsx": () => import("./../../../src/pages/progress-photos.tsx" /* webpackChunkName: "component---src-pages-progress-photos-tsx" */),
  "component---src-pages-stack-mdx": () => import("./../../../src/pages/stack.mdx" /* webpackChunkName: "component---src-pages-stack-mdx" */),
  "component---src-templates-mdx-tsx": () => import("./../../../src/templates/mdx.tsx" /* webpackChunkName: "component---src-templates-mdx-tsx" */)
}

